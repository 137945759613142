.game-area {
  position: relative;
  width: 700px; 
  height: 700px; 
  margin: 0 auto;
  background-color: transparent;
}

.room {
  width: 100%;
  height: 100%;
}

.character {
  position: absolute;
  width: 65px; 
  height: 65px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character img {
  width: 120%; 
  height: auto;
}

.message {
  position: absolute;
  bottom: 60px; /* Jarak di atas kepala karakter */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 12px;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
}
.sumber {
  background-color: #b9431fe8;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border-radius: 12px;
  max-width: 300px;
  font-weight:500;
}
.kontainer {
  background-color: #b9431fe8;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border-radius: 12px;
  max-width: 300px;
  font-weight:500
}
.shielding {
  background-color: #b9431fe8;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border-radius: 12px;
  max-width: 300px;
  font-weight:500
}
.kaktus {
  background-color: #b9431fe8;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 12px;
  border-radius: 12px;
  max-width: 300px;
  font-weight:500
}
.message div {
  font-size: 12px;
  line-height: 1.5;
  width: 180px;
}
